
* {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

.time-line {
    width: 1182px;
    margin: 20px auto;
    position: relative;
}

.time-line .left-btn,
.time-line .right-btn {
    width: 30px;
    height: 30px;
    background: url(../assets/img/time-axis/arrow.png) no-repeat;
    position: absolute;
    margin-top: -15px;
    cursor: pointer;
}

.time-line .left-btn {
    left: 0;
    top: 50%;
}

.time-line .right-btn {
    right: 0;
    top: 50%;
    transform: rotate(180deg);
}

.time-line .right-btn:hover {
    background: url(../assets/img/time-axis/arrow-hover.png) no-repeat;
    transform: rotate(180deg);
}

.time-line .left-btn:hover {
    background: url(../assets/img/time-axis/arrow-hover.png) no-repeat;
}

.time-line .time-box {
    width: 1102px;
    margin: 0 auto;
    overflow: hidden;
}

.time-line .time-box ul {
    overflow: hidden;
    position: relative;
    width: 1102px;
    height: 290px;
}

.time-line .time-box ul:after {
    content: '';
    background: #d2d2d2;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
}

.time-line .time-box li {
    width: 275px;
    height: 102px;
    box-sizing: border-box;
    float: left;
    padding: 10px;
    box-sizing: border-box;

    border: 1px solid #d2d2d2;
    position: relative;
    cursor: pointer;
}
.time-line .time-box li h3{
    width: 255px;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
}
.time-line .time-box li p {
    width: 255px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.time-line .time-box li:nth-child(2n) {
    margin-top: 183px;
}

.time-line .time-box li:before {
    content: '';
    border-left: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    position: absolute;
    width: 16px;
    height: 16px;
    background: #fff;
    bottom: -9px;
    transform: rotate(-45deg);
    left: 50%;
    margin-left: -8px;
    background: #ebf0f4;
}

.time-line .time-box li:after {
    content: '';
    width: 15px;
    height: 15px;
    background: url(../assets/img/time-axis/point.png) no-repeat;
    position: absolute;
    left: 50%;
    margin-left: -7px;
    bottom: 0;
    bottom: -51px;
    z-index: 1;
}

.time-line .time-box li:nth-child(2n):before {
    bottom: auto;
    top: -10px;
    transform: rotate(-225deg);
}

.time-line .time-box li:nth-child(2n):after {
    bottom: auto;
    top: -46px;
}

.time-line .time-box li:hover,
.time-line .time-box li.active {
    background: #1b96ff;
    color: #fff;
    border-color: #1b96ff;
}

.time-line .time-box li:hover:before,
.time-line .time-box li.active:before {
    border-color: #1b96ff;
    background: #1b96ff;
}

.time-line .time-box li:hover:after,
.time-line .time-box li.active:after {
    background: url(../assets/img/time-axis/point-hover.png) no-repeat;
}
